import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { IconNames } from "@timhortons/common/src/components/atoms/icon";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { GetModal } from "@timhortons/common/src/components/atoms/modal";
import LpAuditModal from "@timhortons/common/src/modules/lp/organisms/lpAuthModal/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IState } from "@timhortons/common/src/store/interfaces";
import { AuditType } from "@timhortons/common/src/modules/lp/components/dashboard";
import {
  AuditStoresListByCodeOrName,
  GetAuditsStoreListByCodeOrNamePayload,
  LpAuditInfoModel,
  LpPreviousAuditDataModel
} from "@timhortons/common/src/models/lp/audits";
import PreviousAuditsTable from "@timhortons/common/src/modules/lp/components/dashboard/audits/organisms/previousAudits";
import { AuditActions } from "@timhortons/common/src/redux/lp/actions/audits";
import { AuditsSelectors } from "@timhortons/common/src/redux/lp/selectors/audits";
import { IAuditError, IAuditLoading } from "@timhortons/common/src/redux/lp/reducers/audits";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { validations } from "@timhortons/common/src/utils/formHelpers";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import LPAuditValidationModal from "@timhortons/common/src/modules/lp/organisms/AuditValidationModal";

interface IProps {
  history: any;
  translate?: any;
  user?: IUserDetails;
  resetStoreListState?: () => void;
  getLpStoresLists?: AuditStoresListByCodeOrName;
  checkLpAuditAuthID: Function;
  isIndividualLogin?: boolean;
  getLpAuditStoreListByStoreCodeOrName?: (value: GetAuditsStoreListByCodeOrNamePayload) => void;
  error: IAuditError;
  loading: IAuditLoading;
  lpPreviousAuditData: LpPreviousAuditDataModel;
  getLpPreviousAuditData: Function;
  deleteLpAudit: Function;
  checkLpAuth: LpAuditInfoModel;
  resetLpAuthID: Function;
}

function Audit(props: IProps): ReactElement {
  const {
    translate,
    user,
    history,
    resetStoreListState,
    getLpStoresLists,
    checkLpAuditAuthID,
    isIndividualLogin,
    getLpAuditStoreListByStoreCodeOrName,
    error,
    loading,
    lpPreviousAuditData,
    getLpPreviousAuditData,
    deleteLpAudit,
    checkLpAuth,
    resetLpAuthID
  } = props;

  const isFocused = useFocusedHook();
  const [modalVisible, setModalVisible] = useState(false);
  const [showAuditErrorModal, setShowAuditErrorModal] = useState(false);
  const [showAuditInValid, setShowAuditInValidModal] = useState(false);
  const [consolidatedDate, setConsolidatedDates] = useState({
    startDate: null,
    endDate: null
  });
  const setModalState = (): void => {
    setModalVisible(!modalVisible);
  };

  const setInValidAuditModalState = (): void => {
    setShowAuditInValidModal(!showAuditInValid);
    resetLpAuthID();
  };
  const handleAuditAuth = async (storeID: string, audit: AuditType) => {
    checkLpAuditAuthID({
      storeId: storeID,
      startTime: new Date(),
      auditedBy: user?.empId,
      auditType: AuditType.STANDARD,
      isIndividualLogin: isIndividualLogin,
      isCustomDate: consolidatedDate.startDate === null ? false : true,
      customDate: consolidatedDate.startDate
    });
  };
  useEffect(() => {
    setShowAuditErrorModal(false);
    if (checkLpAuth && checkLpAuth.storeName !== null && error.lpCheckAuth === null) {
      if (checkLpAuth.isAuditInValid) {
        setShowAuditInValidModal(!showAuditInValid);
      } else {
        let searchParams = { id: checkLpAuth.auditId, type: checkLpAuth.auditType };
        navigation(history, Pathnames.lpAudit, searchParams);
      }
    } else if (error.lpCheckAuth !== null) {
      setShowAuditErrorModal(!showAuditErrorModal);
    }
  }, [checkLpAuth, error.lpCheckAuth]);

  useEffect(() => {
    isFocused &&
      user &&
      getLpPreviousAuditData({
        empId: user?.empId ?? user?.storeCode,
        role: user?.roles[0]
      });
  }, [user, isFocused]);

  return (
    <>
      {(user.roles.includes(Roles.HeadLP) ||
        user.roles.includes(Roles.AuditorLP) ||
        // user.roles.includes(Roles.Admin) ||
        user.roles.includes(Roles.COO) ||
        user.roles.includes(Roles.CEO) ||
        user.roles.includes(Roles.HrAndAdminDirector) ||
        user.roles.includes(Roles.ProductionAndDevExecutive) ||
        user.roles.includes(Roles.TrainingAndDevManager) ||
        user.roles.includes(Roles.TrainingAndDevLead) ||
        user.roles.includes(Roles.SuperAdmin)) && (
        <View>
          <View style={styles.ButtonContainer}>
            <Button
              type={ButtonType.Secondary}
              title={translate("createAudit")}
              buttonStyles={styles.ButtonStyle}
              onPress={() => setModalVisible(true)}
              textStyles={styles.ctaTextStyle}
              textSize={TextSize.Regular}
              fontWeight={FontWeight.SemiBold}
              iconName={IconNames.plus}
              iconSize={12}
              iconStyles={styles.ctaIconStyle}
              disabled={lpPreviousAuditData ? true : false}
            />
          </View>
          {error.lpPreviousAuditData ? (
            <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
          ) : loading.lpPreviousAuditData ? (
            <Loader type="component" />
          ) : (
            lpPreviousAuditData && (
              <PreviousAuditsTable
                translate={translate}
                previousAuditData={lpPreviousAuditData}
                deleteAudit={deleteLpAudit}
                history={history}
              />
            )
          )}
          {modalVisible && (
            <GetModal setModalState={setModalState}>
              <LpAuditModal
                resetStoreListState={resetStoreListState}
                getStoresLists={getLpStoresLists}
                handleAuth={handleAuditAuth}
                translate={translate}
                modalVisible={modalVisible}
                setModalState={setModalState}
                isIndividualLogin={isIndividualLogin}
                user={user}
                getStoreListByStoreCodeOrName={getLpAuditStoreListByStoreCodeOrName}
                error={error?.getLpAuditStoreListByCodeOrName}
                widthTextField={376}
                consolidatedDate={consolidatedDate}
                setConsolidatedDates={setConsolidatedDates}
              />
            </GetModal>
          )}
          {showAuditInValid && (
            <GetModal setModalState={setInValidAuditModalState}>
              <LPAuditValidationModal
                setShowModal={setInValidAuditModalState}
                translate={translate}
                restaurantName={checkLpAuth?.storeName}
                restaurantCode={checkLpAuth?.storeCode}
              />
            </GetModal>
          )}
          {showAuditErrorModal ? (
            <AlertModal
              setModalVisible={() => {
                setShowAuditErrorModal(!showAuditErrorModal);
              }}
              errorMsg={validations.notAValidUser}
            />
          ) : null}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  ButtonContainer: {
    flex: 1,
    marginVertical: 30
  },
  ButtonStyle: {
    flex: 1,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: 8,
    paddingVertical: 8
  },

  ctaTextStyle: {
    marginTop: 2
  },
  ctaIconStyle: {
    ...I18nService.select({
      rtl: { marginEnd: 6 },
      ltr: { marginEnd: 6 }
    })
  }
});
const mapStateToProps = (state: IState) => {
  return {
    lpPreviousAuditData: AuditsSelectors.getLpPreviousAuditData(state),
    loading: AuditsSelectors.auditLoading(state),
    error: AuditsSelectors.auditError(state),
    getLpStoresLists: AuditsSelectors.getLpAuditStoresListByStoreCodeName(state),
    checkLpAuth: AuditsSelectors.checkLpAuditAuth(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...AuditActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
