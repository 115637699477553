import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { AuditActions } from "@timhortons/common/src/redux/lp/actions/audits";
import {
  LpAuditInfoModel,
  GetAuditsStoreListByCodeOrNamePayload,
  AuditStoresListByCodeOrName,
  LpPreviousAuditDataModel,
  LpSubCategoryModel,
  LpYesNoDataModel,
  LpObservationDataModel,
  PostLpCommentModel,
  LpCommentDataModel,
  LpTippingPointsModel,
  LpPostObservationSuccessModel
} from "@timhortons/common/src/models/lp/audits";
import { Logger } from "@timhortons/common/src/services/logger";
import { AuditType } from "@timhortons/common/src/modules/lp/components/dashboard";
import { Roles } from "@timhortons/common/src/constants/roles";
import { auditsRepository } from "@timhortons/common/src/repositories/lp/audits";
import { LpCategoryActions } from "@timhortons/common/src/redux/lp/actions/category";

function* checkLpAuditAuthIDWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    startTime: Date;
    auditedBy: number;
    auditType: AuditType;
    isIndividualLogin: boolean;
    isCustomDate: boolean;
    customDate: Date;
  }>
) {
  try {
    const { payload } = value;

    let response: LpAuditInfoModel = yield call(auditsRepository.checkLpAuthID, payload);
    yield put(AuditActions.checkLpAuditAuthIDSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "checkLpAuditAuthIDWorkerSaga");

    yield put(AuditActions.checkLpAuditAuthIDError(error));
  }
}
function* getLpAuditStoreListByCodeOrNameWorkerSaga(
  value: IFluxStandardAction<GetAuditsStoreListByCodeOrNamePayload>
) {
  const { payload } = value;
  try {
    const response: AuditStoresListByCodeOrName = yield call(
      auditsRepository.getAuditStoreListByStoreCodeOrName,
      payload
    );

    yield put(AuditActions.getLpAuditStoreListByStoreCodeOrNameSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpAuditStoreListByCodeOrNameWorkerSaga");

    yield put(AuditActions.getLpAuditStoreListByStoreCodeOrNameError(error));
  }
}
function* getLpPreviousAuditDataWorkerSaga(
  value: IFluxStandardAction<{
    empId: number;
    role: Roles[];
  }>
) {
  const { payload } = value;
  try {
    const response: LpPreviousAuditDataModel = yield call(
      auditsRepository.fetchLpPreviousAudit,
      payload
    );

    yield put(AuditActions.getLpPreviousAuditDataSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpPreviousAuditDataWorkerSaga");

    yield put(AuditActions.getLpPreviousAuditDataError(error));
  }
}
function* deleteLpAuditWorkerSaga(value: IFluxStandardAction<{ auditId: number }>) {
  const { payload } = value;
  try {
    yield call(auditsRepository.deleteLpAudit, payload);
    yield put(AuditActions.deleteLpAuditSuccess());
  } catch (error) {
    Logger.error("lp-audits", error, "deleteLpAuditWorkerSaga");

    yield put(AuditActions.deleteLpAuditError(error));
  }
}
function* resumeLpAuditWorkerSaga(
  value: IFluxStandardAction<{ auditId: number; type: AuditType }>
) {
  const { payload } = value;
  try {
    const response: LpAuditInfoModel = yield call(auditsRepository.resumeAudit, payload);
    yield put(AuditActions.checkLpAuditAuthIDSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "resumeLpAuditWorkerSaga");

    yield put(AuditActions.checkLpAuditAuthIDError(error));
  }
}

function* getLpSubCategoryDataWorkerSaga(
  value: IFluxStandardAction<{
    auditId: number;
    categoryId: string;
    auditType: AuditType;
    storeCode: number;
  }>
) {
  const { payload } = value;

  try {
    const response: LpSubCategoryModel = yield call(
      auditsRepository.fetchLpSubCategoryData,
      payload
    );
    yield put(AuditActions.getLpSubCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpSubCategoryDataWorkerSaga");

    yield put(AuditActions.getLpSubCategoryDataError(error));
  }
}
function* getLpTippingPointsWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    auditType: AuditType;
  }>
) {
  try {
    const { payload } = value;
    const response: LpTippingPointsModel = yield call(
      auditsRepository.fetchTippingPointsData,
      payload
    );
    yield put(AuditActions.getLpTippingPointsSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpTippingPointsWorkerSaga");

    yield put(AuditActions.getLpTippingPointsError(error));
  }
}
function* postLpAnswerYesOrNoWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    auditId: number;
    status: string;
    subCategoryId: number;
    auditType: AuditType;
    currentCategory?: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpYesNoDataModel = yield call(auditsRepository.postLpAnswerYesOrNo, payload);
    yield put(
      AuditActions.lpAnswerYesNOSuccess({
        apiRes: response,
        metaData: {
          subCategoryId: payload.subCategoryId,
          questionId: payload.questionId,
          status: payload.status
        }
      })
    );
    yield put(LpCategoryActions.updateLpCategoryData(response, payload.currentCategory));
    if (payload.status === "NO") {
      yield put(
        AuditActions.getLpObservationData({
          auditId: payload.auditId,
          questionId: payload.questionId
        })
      );
    }
  } catch (error) {
    Logger.error("lp-audits", error, "postLpAnswerYesOrNoWorkerSaga");

    yield put(AuditActions.lpAnswerYesNOError(error));
  }
}
function* getLpObservationDataWorkerSaga(
  value: IFluxStandardAction<{
    auditId: number;
    questionId: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpObservationDataModel = yield call(
      auditsRepository.fetchLpObservationData,
      payload
    );
    yield put(AuditActions.getLpObservationDataSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpObservationDataWorkerSaga");

    yield put(AuditActions.getLpObservationDataError(error));
  }
}
function* postLpObservationWorkerSaga(
  value: IFluxStandardAction<{
    questionId: number;
    observationId: number;
    auditId: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpPostObservationSuccessModel = yield call(
      auditsRepository.postObservationSelectionData,
      payload
    );
    yield put(AuditActions.postLpObservationSelectionSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "postLpObservationWorkerSaga");

    yield put(AuditActions.postLpObservationSelectionError(error));
  }
}
function* updateLpCommentDataWorkerSaga(value: IFluxStandardAction<PostLpCommentModel>) {
  try {
    const { payload } = value;
    const response: LpCommentDataModel = yield call(auditsRepository.updateLpCommentData, payload);
    yield put(AuditActions.updateLpCommentDataSuccess(response, payload));
  } catch (error) {
    Logger.error("lp-audits", error, "updateLpCommentDataWorkerSaga");

    yield put(AuditActions.updateLpCommentDataError(error));
  }
}
function* getLpCommentDataWorkerSaga(value: IFluxStandardAction<PostLpCommentModel>) {
  try {
    const { payload } = value;
    const response: LpCommentDataModel = yield call(auditsRepository.postLpCommentData, payload);
    yield put(AuditActions.postLpCommentDataSuccess(response, payload));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpCommentDataWorkerSaga");

    yield put(AuditActions.postLpCommentDataError(error));
  }
}
function* getLpFeedbackDataWorkerSaga(value: IFluxStandardAction<LpCommentDataModel>) {
  try {
    const { payload } = value;
    const response: LpCommentDataModel = yield call(auditsRepository.getLpCommentData, payload);
    yield put(AuditActions.getLpFeedbackDataSuccess(response));
  } catch (error) {
    Logger.error("lp-audits", error, "getLpFeedbackDataWorkerSaga");

    yield put(AuditActions.getLpFeedbackDataError(error));
  }
}
function* auditsWatcherSaga() {
  yield takeLatest(LP.CHECK_LP_AUDIT_AUTH_ID.LOADING, checkLpAuditAuthIDWorkerSaga);
  yield takeLatest(
    LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.LOADING,
    getLpAuditStoreListByCodeOrNameWorkerSaga
  );
  yield takeLatest(LP.GET_LP_PREVIOUS_AUDIT_DATA.LOADING, getLpPreviousAuditDataWorkerSaga);
  yield takeLatest(LP.RESUME_AUDIT.LOADING, resumeLpAuditWorkerSaga),
    yield takeLatest(LP.DELETE_LP_AUDIT.LOADING, deleteLpAuditWorkerSaga);
  yield takeLatest(LP.GET_LP_SUB_CATEGORY_DATA.LOADING, getLpSubCategoryDataWorkerSaga);
  yield takeLatest(LP.GET_TIPPING_POINTS_DATA.LOADING, getLpTippingPointsWorkerSaga);
  yield takeLatest(LP.LP_ANSWER_YES_NO_NA_DATA.LOADING, postLpAnswerYesOrNoWorkerSaga);
  yield takeEvery(LP.GET_LP_OBSERVATION_DATA.LOADING, getLpObservationDataWorkerSaga);
  yield takeLatest(LP.LP_POST_OBSERVATION_DATA.LOADING, postLpObservationWorkerSaga);
  yield takeLatest(LP.UPDATE_LP_COMMENT_DATA.LOADING, updateLpCommentDataWorkerSaga);
  yield takeEvery(LP.POST_LP_COMMENT_DATA.LOADING, getLpCommentDataWorkerSaga);
  yield takeEvery(LP.GET_LP_COMMENT_DATA.LOADING, getLpFeedbackDataWorkerSaga);
}

export default auditsWatcherSaga;
