import { deserialize } from "@timhortons/common/src/mappers/apiResMapper";
import {
  GetAuditsStoreListByCodeOrNamePayload,
  AuditStoresListModel,
  AuditStoresListByCodeOrName,
  LpCategoryDataModel,
  LpAuditInfoModel,
  LpSubCategoryInfoModel,
  LpYesNoDataModel,
  LpObservationDataModel,
  LpObservationDataPointsModel,
  PostLpCommentModel,
  LpCommentDataModel,
  ResumeLpAuditInfoModel,
  LpTippingPointsDataModel,
  LpPostObservationSuccessModel
} from "@timhortons/common/src/models/lp/audits";
import { ApiClient } from "@timhortons/common/src/network/apiClient";
import { LPEndPoints } from "@timhortons/common/src/constants/endPoints";

import { AuditType } from "@timhortons/common/src/modules/lp/components/dashboard";
import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import { IApiClientOptions } from "@timhortons/common/src/network/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { isEmpty } from "lodash";

export const validateLpAuthModel = async (
  isIndividualLogin: boolean,
  auditedBy: number,
  storeCode: number,
  headers: IApiClientOptions
) => {
  let validateStore;
  let params = {
    storeCode: storeCode,
    employeeId: auditedBy
  };
  if (isIndividualLogin) {
    validateStore = await ApiClient.get(
      LPEndPoints.audits.lpIndividualAccessValidation(),
      params,
      headers
    );
  } else if (!isIndividualLogin) {
    validateStore = await ApiClient.get(
      LPEndPoints.appeals.lpStoreAccessValidation(),
      { ...params, managerAccess: false },
      headers
    );
  }
  return validateStore;
};

class AuditsRepository {
  private keysBasedOnType = {
    [AuditType.STANDARD]: LPKeys.LP_STANDARD_AUDIT
  };
  checkLpAuthID = async (param: {
    storeId: number;
    startTime: Date;
    auditedBy: number;
    auditType: AuditType;
    isIndividualLogin: boolean;
    isCustomDate: boolean;
    customDate: Date;
  }) => {
    let paramFinal = {
      storeCode: param.storeId,
      startTime: param.startTime,
      auditedBy: param.auditedBy,
      auditType: param.auditType,
      isCustomDate: param.isCustomDate,
      customDate: param.customDate
    };
    let data;
    let validateStore = await validateLpAuthModel(
      param.isIndividualLogin,
      param.auditedBy,
      param.storeId,
      this.keysBasedOnType[param.auditType]
    );
    if (validateStore) {
      data = await ApiClient.post(
        LPEndPoints.audits.createLpStandardAudit(),
        paramFinal,
        null,
        LPKeys.LP_STANDARD_AUDIT
      );
    }
    return deserialize(LpAuditInfoModel, data);
  };

  getAuditStoreListByStoreCodeOrName = async (param: GetAuditsStoreListByCodeOrNamePayload) => {
    const { pageNo, pageSize, keyword, role } = param;
    let data = await ApiClient.get(
      LPEndPoints.audits.getAuditStoreListByCodeOrName(param.empId),
      {
        pageNo,
        pageSize,
        keyword,
        role
      },
      LPKeys.LP_STANDARD_AUDIT
    );
    let storesData: AuditStoresListByCodeOrName = {
      content: [],
      totalElements: null
    };
    let finalContent: AuditStoresListModel[] = [];
    data.content.map((item: AuditStoresListModel) => {
      let deserializevar: AuditStoresListModel = deserialize(AuditStoresListModel, item);
      finalContent.push(deserializevar);
    });
    storesData.totalElements = data.totalElements;
    storesData.content = finalContent;
    storesData.currentPage = pageNo;
    return storesData;
  };

  fetchLpPreviousAudit = async (param: { empId: number }) => {
    const { empId } = param;
    let data = ApiClient.get(
      LPEndPoints.audits.fetchPreviousAuditStore(empId),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    return data;
  };
  deleteLpAudit = async (param: { auditId: number }) => {
    const { auditId } = param;
    await ApiClient.delete(
      LPEndPoints.audits.deleteLpAudit(auditId),
      null,
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
  };
  resumeAudit = async (param: { auditId: number; type: AuditType }) => {
    const { auditId, type } = param;
    let data = await ApiClient.get(
      LPEndPoints.audits.deleteLpAudit(auditId),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    data.auditId = auditId;
    data.isCompleted = false;
    try {
      return deserialize(ResumeLpAuditInfoModel, data);
    } catch (error) {
      Logger.error(JSON.stringify("repo/lp/audits", error, "resumeAudit"));
      return null;
    }
  };

  fetchLpCategoryData = async (param: { auditId: number; auditType: AuditType }) => {
    let data = await ApiClient.get(
      LPEndPoints.audits.fetchLpCategory(param.auditId),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    return deserialize(LpCategoryDataModel, data);
  };

  fetchLpSubCategoryData = async (param: {
    auditId: number;
    categoryId: string;
    auditType: AuditType;
    storeCode: number;
  }) => {
    const { auditId, categoryId, storeCode } = param;
    let data = await ApiClient.get(
      LPEndPoints.audits.fetchLpSubCategory(storeCode, auditId, Number(categoryId)),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    let lpSubCategoryData: LpSubCategoryInfoModel[] = [];
    try {
      data.map((item: LpSubCategoryInfoModel) => {
        let deserializevar: LpSubCategoryInfoModel = deserialize(LpSubCategoryInfoModel, item);
        lpSubCategoryData.push(deserializevar);
      });
      return {
        auditId: auditId,
        categoryId: categoryId,
        lpSubCategories: lpSubCategoryData
      };
    } catch (error) {
      Logger.error(JSON.stringify("repo/lp/audits", error, "fetchLpSubCategoryData"));
      return null;
    }
  };
  fetchTippingPointsData = async (param: { questionId: number; auditType: string }) => {
    let data = await ApiClient.get(
      LPEndPoints.audits.fetchTippingPoints(param.questionId),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    let tippingPointsData: LpTippingPointsDataModel[] = [];
    data.map((item: LpTippingPointsDataModel) => {
      let deserializeVar: LpTippingPointsDataModel = deserialize(LpTippingPointsDataModel, item);
      tippingPointsData.push(deserializeVar);
    });
    return {
      tippingPoints: tippingPointsData
    };
  };
  postLpAnswerYesOrNo = async (param: {
    subCategoryId: number;
    questionId: number;
    auditId: number;
    status: string;
    auditType: AuditType;
  }) => {
    const { subCategoryId, questionId, auditId, status, auditType } = param;
    let data = await ApiClient.post(
      LPEndPoints.audits.postLpAnswer(),
      {
        subCategoryId,
        questionId,
        auditId,
        status,
        auditType
      },
      null,
      LPKeys.LP_STANDARD_AUDIT
    );

    return deserialize(LpYesNoDataModel, data);
  };

  fetchLpObservationData = async (param: { auditId: number; questionId: number }) => {
    let { auditId, questionId } = param;
    let data = await ApiClient.get(
      LPEndPoints.audits.lpObservationData(auditId, questionId),
      {},
      LPKeys.LP_STANDARD_AUDIT
    );

    let finalData: LpObservationDataModel = { questionId: 0, rejectedDataPoints: [] };

    if (data.length > 0) {
      data.map((item) => {
        let deserializevar: LpObservationDataPointsModel = deserialize(
          LpObservationDataPointsModel,
          item
        );
        finalData.rejectedDataPoints.push(deserializevar);
      });

      finalData.questionId = finalData.rejectedDataPoints[0].id;
    }
    return finalData;
  };
  postObservationSelectionData = async (param: {
    questionId: number;
    observationId: number;
    auditId: number;
  }) => {
    let data = await ApiClient.post(
      LPEndPoints.audits.postObservationSelection(),
      param,
      null,
      LPKeys.LP_STANDARD_AUDIT
    );

    return deserialize(LpPostObservationSuccessModel, data);
  };
  postLpCommentData = async (param: PostLpCommentModel) => {
    let headers = {
      ...LPKeys.LP_STANDARD_AUDIT,
      "Content-Type": "multipart/form-data"
    };
    let data = await ApiClient.post(
      LPEndPoints.audits.postLpCommentData(param.subCategoryId, param.auditId, param.questionId),
      param.body,
      null,
      headers
    );
    return deserialize(LpCommentDataModel, data);
  };

  updateLpCommentData = async (param: PostLpCommentModel) => {
    let headers = {
      ...LPKeys.LP_STANDARD_AUDIT,
      "Content-Type": "multipart/form-data"
    };

    let data = await ApiClient.put(
      LPEndPoints.audits.postLpCommentData(param.subCategoryId, param.auditId, param.questionId),
      param.body,
      null,
      headers
    );
    return deserialize(LpCommentDataModel, data);
  };
  getLpCommentData = async (param: LpCommentDataModel) => {
    let data = await ApiClient.get(
      LPEndPoints.audits.getLpCommentData(param.subCategoryId, param.auditId, param.questionId),
      null,
      LPKeys.LP_STANDARD_AUDIT
    );
    if (isEmpty(data)) {
      data.auditId = param.auditId;
      data.subcategoryId = param.subCategoryId;
      data.questionId = param.questionId;
      data.files = param.files;
      data.comment = "";
      return data;
    } else {
      return deserialize(LpCommentDataModel, data);
    }
  };
}

const auditsRepository = new AuditsRepository();
export { auditsRepository as auditsRepository };
