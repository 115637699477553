import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  LpAuditInfoModel,
  GetAuditsStoreListByCodeOrNamePayload,
  AuditStoresListByCodeOrName,
  LpPreviousAuditDataModel,
  LpSubCategoryModel,
  LpYesNoDataModel,
  LpObservationDataModel,
  PostLpCommentModel,
  LpCommentDataModel,
  LpTippingPointsModel,
  LpPostObservationSuccessModel
} from "@timhortons/common/src/models/lp/audits";
import { AuditType } from "@timhortons/common/src/modules/lp/components/dashboard";
import { Roles } from "@timhortons/common/src/constants/roles";

const checkLpAuditAuthID = (data: {
  storeId: number;
  startTime: Date;
  auditedBy: number;
  auditType: AuditType;
  isIndividualLogin: boolean;
  isCustomDate: boolean;
  customDate: Date;
}): IFluxStandardAction<{
  storeId: number;
  startTime: Date;
  auditedBy: number;
  auditType: AuditType;
  isIndividualLogin: boolean;
  isCustomDate: boolean;
  customDate: Date;
}> => {
  return {
    type: LP.CHECK_LP_AUDIT_AUTH_ID.LOADING,
    payload: data
  };
};

const checkLpAuditAuthIDSuccess = (
  data: LpAuditInfoModel
): IFluxStandardAction<LpAuditInfoModel> => {
  return {
    type: LP.CHECK_LP_AUDIT_AUTH_ID.SUCCESS,
    payload: data
  };
};

const checkLpAuditAuthIDError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.CHECK_LP_AUDIT_AUTH_ID.ERROR,
    payload: error
  };
};

const resetLpAuthID = () => {
  return {
    type: LP.RESET_LP_AUTH_ID
  };
};

const getLpAuditStoreListByStoreCodeOrName = (value: GetAuditsStoreListByCodeOrNamePayload) => {
  return {
    type: LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.LOADING,
    payload: value
  };
};
const getLpAuditStoreListByStoreCodeOrNameSuccess = (
  data: AuditStoresListByCodeOrName
): IFluxStandardAction<AuditStoresListByCodeOrName> => {
  return {
    type: LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.SUCCESS,
    payload: data
  };
};
const getLpAuditStoreListByStoreCodeOrNameError = (error: Error) => {
  return {
    type: LP.GET_LP_AUDIT_STORELIST_BY_CODE_OR_NAME.ERROR,
    payload: error
  };
};
const getLpPreviousAuditData = (data: {
  empId: number;
  role: Roles[];
}): IFluxStandardAction<{
  empId: number;
  role: Roles[];
}> => {
  return {
    type: LP.GET_LP_PREVIOUS_AUDIT_DATA.LOADING,
    payload: data
  };
};
const getLpPreviousAuditDataSuccess = (
  data: LpPreviousAuditDataModel
): IFluxStandardAction<LpPreviousAuditDataModel> => {
  return {
    type: LP.GET_LP_PREVIOUS_AUDIT_DATA.SUCCESS,
    payload: data
  };
};
const getLpPreviousAuditDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_PREVIOUS_AUDIT_DATA.ERROR,
    payload: error
  };
};
const deleteLpAudit = (data: { auditId: number }): IFluxStandardAction<{ auditId: number }> => {
  return {
    type: LP.DELETE_LP_AUDIT.LOADING,
    payload: data
  };
};
const deleteLpAuditSuccess = () => {
  return {
    type: LP.DELETE_LP_AUDIT.SUCCESS
  };
};
const deleteLpAuditError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.DELETE_LP_AUDIT.ERROR,
    payload: error
  };
};
const resumeAudit = (data: {
  auditId: number;
  type: AuditType;
}): IFluxStandardAction<{ auditId: number; type: AuditType }> => {
  return {
    type: LP.RESUME_AUDIT.LOADING,
    payload: data
  };
};
const lpUpdateCompletionPercentage = () => {
  return {
    type: LP.LP_UPDATE_COMPLETION.SUCCESS
  };
};
const getLpSubCategoryData = (data: {
  auditId: number;
  categoryId: string;
  auditType: AuditType;
  storeCode: number;
}): IFluxStandardAction<{
  auditId: number;
  categoryId: string;
  auditType: AuditType;
  storeCode: number;
}> => {
  return {
    type: LP.GET_LP_SUB_CATEGORY_DATA.LOADING,
    payload: data
  };
};
const getLpSubCategoryDataSuccess = (
  data: LpSubCategoryModel
): IFluxStandardAction<LpSubCategoryModel> => {
  return {
    type: LP.GET_LP_SUB_CATEGORY_DATA.SUCCESS,
    payload: data
  };
};
const getLpSubCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_SUB_CATEGORY_DATA.ERROR,
    payload: error
  };
};
const getLpTippingPoints = (data: {
  questionId: number;
  auditType: AuditType;
}): IFluxStandardAction<{
  questionId: number;
  auditType: AuditType;
}> => {
  return {
    type: LP.GET_TIPPING_POINTS_DATA.LOADING,
    payload: data
  };
};

const getLpTippingPointsSuccess = (
  data: LpTippingPointsModel
): IFluxStandardAction<LpTippingPointsModel> => {
  return {
    type: LP.GET_TIPPING_POINTS_DATA.SUCCESS,
    payload: data
  };
};
const getLpTippingPointsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_TIPPING_POINTS_DATA.ERROR,
    payload: error
  };
};
const lpAnswerYesNO = (data: {
  questionId: number;
  auditId: number;
  status: string;
  currentCategory?: number;
  subCategoryId: number;
  auditType: AuditType;
}): IFluxStandardAction<{
  questionId: number;
  auditId: number;
  status: string;
  currentCategory?: number;
  subCategoryId: number;
  auditType: AuditType;
}> => {
  return {
    type: LP.LP_ANSWER_YES_NO_NA_DATA.LOADING,
    payload: data
  };
};

const lpAnswerYesNOSuccess = (data: {
  apiRes: LpYesNoDataModel;
  metaData: { subCategoryId: number; questionId: number; status: string };
}): IFluxStandardAction<{
  apiRes: LpYesNoDataModel;
  metaData: { subCategoryId: number; questionId: number; status: string };
}> => {
  return {
    type: LP.LP_ANSWER_YES_NO_NA_DATA.SUCCESS,
    payload: data
  };
};
const lpAnswerYesNOError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_ANSWER_YES_NO_NA_DATA.ERROR,
    payload: error
  };
};

const getLpObservationData = (data: {
  auditId: number;
  questionId: number;
}): IFluxStandardAction<{
  auditId: number;
  questionId: number;
}> => {
  return {
    type: LP.GET_LP_OBSERVATION_DATA.LOADING,
    payload: data
  };
};
const getLpObservationDataSuccess = (
  data: LpObservationDataModel
): IFluxStandardAction<LpObservationDataModel> => {
  return {
    type: LP.GET_LP_OBSERVATION_DATA.SUCCESS,
    payload: data
  };
};
const getLpObservationDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.GET_LP_OBSERVATION_DATA.ERROR,
    payload: error
  };
};
const postLpObservationSelection = (data: {
  questionId: number;
  observationId: number;
  auditId: number;
}): IFluxStandardAction<{
  questionId: number;
  observationId: number;
  auditId: number;
}> => {
  return {
    type: LP.LP_POST_OBSERVATION_DATA.LOADING,
    payload: data
  };
};

const postLpObservationSelectionSuccess = (
  data: LpPostObservationSuccessModel
): IFluxStandardAction<LpPostObservationSuccessModel> => {
  return {
    type: LP.LP_POST_OBSERVATION_DATA.SUCCESS,
    payload: data
  };
};
const postLpObservationSelectionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.LP_POST_OBSERVATION_DATA.ERROR,
    payload: error
  };
};
const updateLpCommentData = (data: PostLpCommentModel) => {
  return {
    type: LP.UPDATE_LP_COMMENT_DATA.LOADING,
    payload: data
  };
};
const updateLpCommentDataSuccess = (data: LpCommentDataModel, value: PostLpCommentModel) => {
  return {
    type: LP.UPDATE_LP_COMMENT_DATA.SUCCESS,
    payload: { data, value }
  };
};
const updateLpCommentDataError = (error: Error) => {
  return {
    type: LP.UPDATE_LP_COMMENT_DATA.ERROR,
    payload: error
  };
};
const postLpCommentData = (data: PostLpCommentModel) => {
  return {
    type: LP.POST_LP_COMMENT_DATA.LOADING,
    payload: data
  };
};
const postLpCommentDataSuccess = (data: LpCommentDataModel, value: PostLpCommentModel) => {
  return {
    type: LP.POST_LP_COMMENT_DATA.SUCCESS,
    payload: { data, value }
  };
};
const postLpCommentDataError = (error: Error) => {
  return {
    type: LP.POST_LP_COMMENT_DATA.ERROR,
    payload: error
  };
};
const getLpFeedbackData = (data: PostLpCommentModel) => {
  return {
    type: LP.GET_LP_COMMENT_DATA.LOADING,
    payload: data
  };
};
const getLpFeedbackDataSuccess = (data: LpCommentDataModel) => {
  return {
    type: LP.GET_LP_COMMENT_DATA.SUCCESS,
    payload: data
  };
};
const getLpFeedbackDataError = (error: Error) => {
  return {
    type: LP.GET_LP_COMMENT_DATA.ERROR,
    payload: error
  };
};
export const AuditActions = {
  checkLpAuditAuthID,
  checkLpAuditAuthIDSuccess,
  checkLpAuditAuthIDError,
  getLpAuditStoreListByStoreCodeOrName,
  getLpAuditStoreListByStoreCodeOrNameSuccess,
  getLpAuditStoreListByStoreCodeOrNameError,
  lpAnswerYesNO,
  lpAnswerYesNOSuccess,
  lpAnswerYesNOError,
  getLpFeedbackData,
  getLpFeedbackDataSuccess,
  getLpFeedbackDataError,
  updateLpCommentData,
  postLpCommentDataSuccess,
  postLpCommentDataError,
  updateLpCommentDataSuccess,
  updateLpCommentDataError,
  postLpCommentData,
  getLpObservationData,
  getLpObservationDataSuccess,
  getLpObservationDataError,
  lpUpdateCompletionPercentage,
  getLpSubCategoryData,
  getLpSubCategoryDataError,
  getLpSubCategoryDataSuccess,
  getLpPreviousAuditData,
  getLpPreviousAuditDataSuccess,
  getLpPreviousAuditDataError,
  deleteLpAudit,
  deleteLpAuditSuccess,
  deleteLpAuditError,
  resumeAudit,
  resetLpAuthID,
  getLpTippingPoints,
  getLpTippingPointsError,
  getLpTippingPointsSuccess,
  postLpObservationSelection,
  postLpObservationSelectionSuccess,
  postLpObservationSelectionError
};
